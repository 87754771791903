import React from "react";
import ReactDOM from "react-dom";
import HookForm from "./HookForm";
import { ThemeProvider, CSSReset, Box } from "@chakra-ui/core";

function App() {
  return (
    <ThemeProvider>
      <CSSReset />
      <Box p={4}>
        <HookForm />
      </Box>
    </ThemeProvider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
